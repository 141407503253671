export const socialMedia_1 = [
  {
    id: 1,
    image:
      "https://hips.hearstapps.com/hmg-prod/images/30th-anniversary-of-apollo-11-landing-on-the-moon-astronaut-news-photo-51098545-1547940625.jpg",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 2,
    image: "https://earthsky.org/upl/2010/03/sun_moon_same_size.jpeg",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 3,
    image:
      "https://img.freepik.com/free-photo/mesmerizing-scenery-green-mountains-with-cloudy-sky-surface_181624-27189.jpg?w=740&t=st=1694156066~exp=1694156666~hmac=e7a4bc777fdd40b37bb40854d3ff5534c5b2af318a87f3351551b0d792d232e8",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 4,
    image:
      "https://img.freepik.com/free-photo/beautiful-shot-waterfall-coming-down-from-mountains_181624-1282.jpg?w=740&t=st=1694156093~exp=1694156693~hmac=b483afd51e4b5c71f7b9652c9eca65a9225f55a3985a7f6166178b9244980835",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 5,
    image:
      "https://img.freepik.com/free-photo/green-sofa-white-living-room-with-free-space_43614-834.jpg?w=740&t=st=1694156155~exp=1694156755~hmac=9c4ffd0afc1c148b2dffa85421be23da48d8e9a563db5e7032650cd6d4deb5a4",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 6,
    image:
      "https://img.freepik.com/premium-photo/bamboo-forest-arashiyama-kyoto-japan_743855-29627.jpg?w=740",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 7,
    image:
      "https://img.freepik.com/free-photo/closeup-shot-common-myna-bird-perched-concrete-surface_181624-56247.jpg?w=740&t=st=1694156256~exp=1694156856~hmac=e482238dd5f8b69ffa65011c795a8c92020dcee760dba4ce7bd7629c6b8cf65a",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
  {
    id: 8,
    image:
      "https://img.freepik.com/free-photo/selective-focus-shot-beautiful-green-honeycreeper-bird-perched-branch_181624-51278.jpg?w=740&t=st=1694156274~exp=1694156874~hmac=b09b9c030e3a1b39b554b737db3b2a58acbe77f2de5e74f147a21b50d6ae5adb",
    show: "Visit Us",
    country: "india",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  },
];
