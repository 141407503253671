import career from "../../assets/airbus/career.png";
import airbuzzLogo from "../../assets/newImages/Airbuzz-logo.png";
import airbuzzLogo2 from "../../assets/newImages/Airbuzz-logo-vertical.png";
import careerImage from "../../assets/newImages/careerImage.png";
import Blogsbg from "../../assets/newImages/Blogs.png";
import CareersBg from "../../assets/newImages/pexels-tima-miroshnichenko-6169033.jpg";
import FaqBg from "../../assets/newImages/toa-heftiba-_UIVmIBB3JU-unsplash.jpg";

export const images = {
  career: career,
  airbuzzLogo: airbuzzLogo,
  footerLogo: airbuzzLogo2,
  CareersBg: CareersBg,
  careerImage: careerImage,
  FaqBg: FaqBg,
  Blogsbg: Blogsbg,
};
