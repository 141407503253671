import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { skyExColors } from "../../../controller/constant";
import { instance } from "../../../utils/api";
import Header from "./Header";
import Estimation from "./Estimation";
import SocialMediaComponent from "./SocialMediaComponent";
import CareersContent from "./CareersContent";
import Services from "./Services";
import FaqContent from "./FaqContent";
import Articles from "./Articles";
import StickyFooter from "./StickyFooter";
import { loadContext } from "../../../App";
import Tracker from "./Tracker";
import { useAirbuzzLocation } from "../../../controller/constant/LocationContext";

const LandingScreen = () => {
  const { isVisible } = useAirbuzzLocation();
  const { setLoading } = useContext(loadContext);
  const [head, setHead] = useState([]);
  const [account, setAccount] = useState([]);
  const [article, setArticle] = useState([]);
  const [service, setService] = useState([]);
  const [title, setTitle] = useState([]);

  const landingScreenDetails = async () => {
    setLoading(true);
    try {
      const response1 = await instance.get(`/Landing/getHeader&EstimationData`);
      const response2 = await instance.get(`/Landing/getOpenAccount&FaqData`);
      const response3 = await instance.get(
        `/Landing/getAirfreight&ArticlesData`
      );
      const response4 = await instance.get(
        `/Landing/getService&SocialFeedsData`
      );

      const response5 = await instance.get(`/Landing/getAllTitles`);
      const failedRequests = [];

      if (response1.status !== 200)
        failedRequests.push("getHeader&EstimationData");
      if (response2.status !== 200)
        failedRequests.push("getOpenAccount&FaqData");
      if (response3.status !== 200)
        failedRequests.push("getAirfreight&ArticlesData");
      if (response4.status !== 200)
        failedRequests.push("getService&SocialFeedsData");
      if (response5.status !== 200) failedRequests.push("getAllTitles");

      if (failedRequests.length === 0) {
        setHead(response1.data);
        setAccount(response2.data);
        setArticle(response3.data);
        setService(response4.data);
        setTitle(response5.data);
      } else {
        console.error(
          `One or more requests failed: ${failedRequests.join(", ")}`
        );
      }
    } catch (err) {
      console.error("An error occurred while fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    landingScreenDetails();
  }, []);

  return (
    <>
      <Wrapper
        class="bg-animation"
        style={{
          marginTop:
            isVisible && window.innerWidth < 600
              ? "50px"
              : window.innerWidth <= 1200
              ? "40px"
              : "0px",
        }}
      >
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
        <div id="stars4"></div>
        <Header data={head.headerData} />
        <br />
        <div style={{ textAlign: "-webkit-center" }}>
          <Tracker />
        </div>
        <br />
        <div id="estimation">
          <Estimation estimationData={head.estimationData} />
        </div>
        <div id="socialMediaComponent">
          <SocialMediaComponent
            data={service.socialFeedsData}
            title={title.feeds}
          />
        </div>
        <CareersContent data={account.accountData} />
        <br />
        <Services data={service?.serviceData} />
        <FaqContent faqData={account.faqData} />
        <br />
        <Articles
          ArticlesData={article.articlesData}
          title={title.articles_title}
          description={title.articles_description}
        />
        <br />
        <br />
        <StickyFooter />
      </Wrapper>
    </>
  );
};

export default LandingScreen;
const Wrapper = styled.section`
  position: relative;
  overflowx: hidden;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    ${skyExColors.primary} 0%,
    ${skyExColors.darkPrimary} 100%
  );
`;
