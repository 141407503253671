import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";

export default function Dropdown({
  label,
  value,
  handleChange,
  displayValue,
  list,
  index,
  name,
  selectedValue,
  error,
}) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel
          id="demo-simple-select-label"
          sx={{ color: error && "#d32f2f" }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={`${label}`}
          value={value}
          name={name}
          onChange={(e) => handleChange(e.target.name, e.target.value, index)}
          InputLabelProps={{ shrink: true }}
          sx={{ bgcolor: "white", height: "100%" }}
          error={error}
        >
          {list ? (
            !displayValue ? (
              list.map((response) => (
                <MenuItem value={response}>{response}</MenuItem>
              ))
            ) : (
              list.map((response) => (
                <MenuItem value={response[selectedValue]}>
                  {response[displayValue]}
                </MenuItem>
              ))
            )
          ) : (
            <MenuItem value={"dd"}>{"ddd"}</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
}
