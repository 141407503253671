export const fontSizes = {
  careerCardContentHeading: {
    xl: "55px",
    lg: "55px",
    md: "50px",
    sm: "40px",
    xs: "40px",
    h1: "30px",
    h2: "24px",
    nameTitle: "28px",
  },
  careerCardContentDescription: {
    xl: "20px",
    lg: "20px",
    md: "18px",
    sm: "16px",
    xs: "14px",
    xxxs: "10px",
    xxs: "12px",
  },
  headings: "48px",
  subHeading: "30px",
  subcontent: "16px",
};
