export const Navbar = [
  {
    id: 1,
    title: "Services",
    menu: [
      { name: "International", path: "/Services/International" },
      { name: "Domestic", path: "/Services/Domestic" },
    ],
  },
  {
    id: 2,
    title: "About",
    path: "/About",
  },
  {
    id: 3,
    title: "Support",
    menu: [
      { name: "FAQ", path: "/Support/FAQ" },
      { name: "Documents", path: "/Support/Downloads" },
    ],
  },
  {
    id: 4,
    title: "Contact",
    menu: [
      { name: "Careers", path: "/Contact/Careers" },
      { name: "Franchise", path: "/Contact/Franchise" },
    ],
  },
  {
    id: 5,
    title: "Blogs",
    path: "/Articles",
  },
  {
    id: 5,
    title: "Login",
    path: "/",
  },
];

export const ShipmentData = [
  {
    id: 9190,
    shipment_date: "2022/12/19 18:02",
    main_order_detail: null,
    awb_logs: [
      {
        id: 18007,
        country: "UAE",
        created: "2022/12/19 18:28",
        message: "Order has been Delivered (Suad Aswad)",
        status: "Delivered",
        status_code: "DLV",
        current_branch: "SHARJAH",
        order: 9190,
        awb: 11509,
        added_by: 13,
      },
      {
        id: 18002,
        country: "UAE",
        created: "2022/12/19 18:27",
        message: "Order is Out for Delivery",
        status: "Out For Delivery",
        status_code: "OFD",
        current_branch: "SHARJAH",
        order: 9190,
        awb: 11509,
        added_by: 13,
      },
      {
        id: 18000,
        country: "UAE",
        created: "2022/12/19 18:27",
        message: "Order is Out Scanned by Driver",
        status: "Out Scanned",
        status_code: "OSC",
        current_branch: "SHARJAH",
        order: 9190,
        awb: 11509,
        added_by: 13,
      },
      {
        id: 17992,
        country: "UAE",
        created: "2022/12/19 18:26",
        message: "Order has been Scheduled For Delivery",
        status: "Scheduled For Delivery",
        status_code: "SFD",
        current_branch: "SHARJAH",
        order: 9190,
        awb: 11509,
        added_by: 754,
      },
      {
        id: 17982,
        country: "UAE",
        created: "2022/12/19 18:21",
        message: "Order is Ready For Delivery",
        status: "Ready for Delivery",
        status_code: "RFD",
        current_branch: "SHARJAH",
        order: 9190,
        awb: 11509,
        added_by: 754,
      },
      {
        id: 17981,
        country: "UAE",
        created: "2022/12/19 18:21",
        message: "Order is Arrived at SHARJAH",
        status: "Arrived At Hub",
        status_code: "AAH",
        current_branch: "SHARJAH",
        order: 9190,
        awb: 11509,
        added_by: 741,
      },
      {
        id: 17968,
        country: "UAE",
        created: "2022/12/19 18:16",
        message: "Order is In Transit to SHARJAH",
        status: "Manifest Out Scanned",
        status_code: "MOSC",
        current_branch: "DUBAI",
        order: 9190,
        awb: 11509,
        added_by: 739,
      },
      {
        id: 17958,
        country: "UAE",
        created: "2022/12/19 18:11",
        message: "Order is Arrived at DUBAI",
        status: "Arrived At Hub",
        status_code: "AAH",
        current_branch: "DUBAI",
        order: 9190,
        awb: 11509,
        added_by: 741,
      },
      {
        id: 17937,
        country: "UAE",
        created: "2022/12/19 18:03",
        message: "Order has been Placed",
        status: "Created",
        status_code: "CRT",
        current_branch: "DUBAI",
        order: 9190,
        awb: 11509,
        added_by: 754,
      },
    ],
    awb_number: "TSKY900003",
    customer_name: "Cotyledon",
    cod_payment_status: "Sky Team Collected",
    modified: "2022/12/19 18:28",
    shipment_type: "Non Doc",
    state: "Outbound",
    status: "Delivered",
    chargeable_weight: "1.00",
    order_rate: "10.00",
    order_type: "Bulk Free Address",
    return_service: false,
    consignment_value: null,
    awb: 11509,
    source_branch: 1,
    destination_branch: 2,
    current_branch: 2,
    source_branch_name: "DUBAI",
    destination_branch_name: "SHARJAH",
    current_branch_name: "SHARJAH",
  },
  {
    id: 9180,
    shipment_date: "2022/12/19 18:01",
    main_order_detail: null,
    awb_logs: [
      {
        id: 17927,
        country: "UAE",
        created: "2022/12/19 18:03",
        message: "Order has been Placed",
        status: "Created",
        status_code: "CRT",
        current_branch: "DUBAI",
        order: 9180,
        awb: 11509,
        added_by: 754,
      },
    ],
    awb_number: "TSKY900003",
    customer_name: "Cotyledon",
    cod_payment_status: "Pending",
    modified: "2022/12/19 18:03",
    shipment_type: "Non Doc",
    state: "Hub",
    status: "Pending Inscan",
    chargeable_weight: "1.00",
    order_rate: "10.00",
    order_type: "Bulk Free Address",
    return_service: false,
    consignment_value: null,
    awb: 11509,
    source_branch: 1,
    destination_branch: 2,
    current_branch: 1,
    source_branch_name: "DUBAI",
    destination_branch_name: "SHARJAH",
    current_branch_name: "DUBAI",
  },
];
