export const skyExTexts = {
  placeholders: {
    title: "CLICK TO ADD TITLE",
    subTitle: "CLICK TO ADD SUBTITLE",
    buttonText: "CLICK TO EDIT",
    discription: "CLICK TO ADD DESCRIPTION",
    name: "CLICK TO ADD NAME",
    position: "ADD POSITION",
    question: "CLICK TO ADD QUESTION",
    answer: "CLICK TO ADD ANSWER",
    link: "CLICK TO ADD LINK",
  },
};
