export const skyExColors = {
  skyExBlue: "#0075C9",
  skyExOrgange: "rgb(255, 164, 0)",
  skyExDarkGrey: "#474747",
  skyExLightGrey: "#A8A7A7",
  skyExTextBoxGrey: "#F4F4F4",
  skyExButtonBlack: "#000",
  skyExButtonOrange: "linear-gradient(180deg, #F6A31F 0%, #C57F10 100%);",
  SkyExGradientOrange: "rgba(255, 164, 0,0.030)",

  primary: "#102C38",
  darkPrimary: "#0a1e26",
  secondary: "#63B233",
  backgroundGradient:
    "linear-gradient(180deg, #102C38 0%, #0d1f26 50%, #102C38 100%);",
  green: "#519534",
  grey: "#B0B0B0",
  textGrey: "#dadada",
  blue: "#102C38",
  pageBackground: "linear-gradient(180deg, #102C38 0%, #0d1f26 100%);",
};
